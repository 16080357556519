import { ADMIN_URL, BASE_URL } from './http';
export class APIService {
  static handleErrors(response: Response) {
    if (response.ok) {
      return response;
    } else {
      console.log('error', response);
      throw Error(response.statusText);
    }
  }

  static async getAttractions(id: number, language = 'de', using_miles = false) {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/attractions?roomId=${id}&locale=${language}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Authorization: 'Token ' + localStorage.getItem('token'),
        },
      });
      await this.handleErrors(response);
      const json = await response.json();
      return json;
    } catch (e) {
      throw e;
    }
  }

  static async testNewsletter(id: number) {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/rooms/${id}/newsletter/test`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Authorization: 'Token ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      });
      await this.handleErrors(response);
      const json = await response.json();
      return json;
    } catch (e) {
      throw e;
    }
  }

  static async calculateRaceIds(id: number) {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/badges/${id}/races`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Authorization: 'Token ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      });
      await this.handleErrors(response);
      const json = await response.json();
      return json;
    } catch (e) {
      throw e;
    }
  }

  static async calculateRanking(id: number) {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/rankings/${id}/calculate`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Authorization: 'Token ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      });
      this.handleErrors(response);
      const json = await response.json();
      return json;
    } catch (e) {
      throw e;
    }
  }

  static async downloadParticipationProgress(id: number) {
    try {
      const response = await fetch(`${BASE_URL}/admin/participation/${id}/download-track-url`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Authorization: 'Token ' + localStorage.getItem('token'),
        },
      });

      // handle errors
      this.handleErrors(response);

      return response;
    } catch (e) {
      // File not available
      return null;
    }
  }

  static async sendBib(participationId: number): Promise<string> {
    try {
      return fetch(`${BASE_URL}/api/v1/participations/${participationId}/send_bib`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Authorization: 'Token ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      })
        .then((response) => this.handleErrors(response))
        .then((response) => response.json());
    } catch (e) {
      throw e;
    }
  }

  static async sendCertificate(participationId: number): Promise<string> {
    try {
      return fetch(`${BASE_URL}/api/v1/participations/${participationId}/send_certificate`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Authorization: 'Token ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      })
        .then((response) => this.handleErrors(response))
        .then((response) => response.json());
    } catch (e) {
      throw e;
    }
  }

  static async uploadFile(file: any, model: string, attribute: string, id?: number): Promise<any> {
    try {
      const data = new FormData();
      data.append('file', file);
      data.append('attribute', attribute);

      const response = await fetch(`${ADMIN_URL}/${model}/${id ? id.toString() + '/' : ''}upload`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        body: data,
      });

      if (response.ok) {
        return response.json();
      } else {
        return null;
      }
    } catch (e) {
      throw e;
    }
  }

  static async deleteFile(id: number, model: string, attribute: string): Promise<any> {
    try {
      const data = new FormData();
      data.append('attribute', attribute);

      const response = await fetch(`${ADMIN_URL}/${model}/${id}/upload`, {
        method: 'DELETE',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        body: data,
      });

      if (response.ok) {
        return response.json();
      } else {
        return null;
      }
    } catch (e) {
      throw e;
    }
  }

  static async getAnalysis(id: number) {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/progress/${id}/analyze`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Authorization: 'Token ' + localStorage.getItem('token'),
        },
      });
      await this.handleErrors(response);
      const json = await response.json();
      return json;
    } catch (e) {
      throw e;
    }
  }

  static async createAttractions(body: any) {
    try {
      const response = await fetch(`${BASE_URL}/admin/attractions/generate/create`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Authorization: 'Token ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      await this.handleErrors(response);
      const json = await response.json();
      return json;
    } catch (e) {
      throw e;
    }
  }

  static async createAttractionsLanguageFallback(body: any) {
    try {
      const response = await fetch(`${BASE_URL}/admin/attraction/create-sound-fallbacks`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Authorization: 'Token ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      await this.handleErrors(response);
      const json = await response.json();
      return json;
    } catch (e) {
      throw e;
    }
  }

  static async toggleAdditionalData(roomId: number, name: string) {
    try {
      const response = await fetch(`${BASE_URL}/admin/room/${roomId}/toggle-additional-data?name=${name}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Authorization: 'Token ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      });
      await this.handleErrors(response);
      const json = await response.json();
      return json;
    } catch (e) {
      throw e;
    }
  }

  static async requestWithoutBodyExtern(url: string) {
    try {
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Accept-Version': 'application/vnd.api.v15',
        },
      });
      await this.handleErrors(response);
      const json = await response.json();
      return json;
    } catch (e) {
      throw e;
    }
  }
}
